import React, { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import useIsBreakpoint from '../../../hooks/use-is-breakpoint/use-is-breakpoint';
import { ArticleType } from '../../../models/article-type';
import { ArticlesCarouselType } from '../../../models/articles-carousel-type';
import BreakpointEnum from '../../../models/breakpoint-enum';
import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import Layout from '../../layout/layout';
import ArticleCard from '../../molecules/article-card/article-card';
import Carousel from '../../molecules/carousel/carousel';

const ArticlesCarousel: FC<ArticlesCarouselType> = ({
  callToAction,
  latestNewsData,
  title,
}: ArticlesCarouselType) => {
  const breakpoint = useIsBreakpoint();
  const breakpoints = {
    0: {
      centeredSlides: true,
      slidesPerGroup: 1,
      slidesPerView: 1.2,
    },
    641: {
      centeredSlides: false,
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    1025: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  };

  return (
    <div className="my-component-spacing-mobile lg:my-component-spacing py-component-spacing-mobile lg:py-component-spacing bg-grey-50 -mx-4 md:-mx-15">
      <Layout>
        <Carousel
          breakpoints={breakpoints}
          heading={title}
          numberOfSlides={latestNewsData.length}
          uniqueId="articles"
        >
          {latestNewsData.map((article: ArticleType) => (
            <SwiperSlide key={article.title}>
              {({ isActive, isVisible }) => (
                <ArticleCard
                  {...article}
                  animate
                  className="bg-white"
                  isActive={
                    breakpoint === BreakpointEnum.sm ? isActive : isVisible
                  }
                />
              )}
            </SwiperSlide>
          ))}
        </Carousel>

        <div className="flex justify-center mt-12">
          <ButtonSecondary {...callToAction} color="lemon-meringue" />
        </div>
      </Layout>
    </div>
  );
};

export default ArticlesCarousel;
