import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import useNextImageLoader from '../../../hooks/use-next-image-loader/use-next-image-loader';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { ArticleType } from '../../../models/article-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import Image from '../../atoms/image/image';
import TextLink from '../../atoms/text-link/text-link';

interface Props extends ArticleType {
  readonly animate?: boolean;
  readonly className?: string;
  readonly isActive?: boolean;
}

const ArticleCard: FC<Props> = ({
  animate,
  className,
  cta,
  image,
  isActive,
  publishDate,
  summary,
  title,
}: Props) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const variants = {
    isActive: { opacity: 1 },
    notActive: { opacity: 0.4 },
  };

  const transition = {
    damping: 18,
    delay: 0.1,
    duration: 0.5,
    type: 'spring',
  };

  return (
    <div
      className={
        `${className} ` +
        'flex flex-col h-full items-center rounded-xl overflow-hidden'
      }
    >
      <motion.div
        animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
        className="relative w-full"
        transition={transition}
        variants={variants}
      >
        <Link href={cta.url} passHref>
          <a href="replace">
            <Image alt={title} loader={useNextImageLoader} model={image} />
          </a>
        </Link>
      </motion.div>

      <motion.div
        animate={!animate || (animate && isActive) ? 'isActive' : 'notActive'}
        className="flex flex-col h-full overflow-hidden pb-6 pt-4 px-6 w-full"
        transition={transition}
        variants={variants}
      >
        <span className="mb-2 text-grey-600 text-sm">
          {dayjs(publishDate).format('YYYY.MM.DD')}
        </span>

        <Link href={cta.url} passHref>
          <a
            className="page-heading-five font-bold mb-2 text-lg"
            href="replace"
          >
            {title}
          </a>
        </Link>

        <p className="h-full mb-6 text-grey-600 text-sm">{summary}</p>

        <TextLink
          {...cta}
          className="ml-auto"
          showIcon
          onClick={() => {
            pushToDataLayer(GtmEventNameEnum.CardNews, {
              cardTitle: title,
            });
          }}
        />
      </motion.div>
    </div>
  );
};

ArticleCard.defaultProps = {
  animate: false,
  className: '',
  isActive: false,
};

export default ArticleCard;
